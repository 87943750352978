import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CanCompConstituency from "../components/candidates/candidatescomponenconstituency"
import CanCompParty from "../components/candidates/candidatescomponent_party"
import { useState } from 'react'
import TableFilter from "../components/candidates/tablefilter"
import CanCompIndividual from "../components/candidates/candidatescomponentinvidual"
import CanComp2Individual from "../components/candidates2/candidates2componentindividual"



class candidates2 extends React.Component {
  constructor({ data, location }) {
    
    super({ data, location });
    this.state = {
      mainFilter: "constituency"
    }
    
    this.siteTitle = data.site.siteMetadata.title;
    this.location = location;
  }

  handlefilterMain = e => {
    const value = e.target.value;

    this.setState({
      mainFilter: value
    });
  };
  

  render() {
    return (
      <Layout location={this.location} title={this.siteTitle}>
      <Seo title="candidates2" />
      
      <CanComp2Individual></CanComp2Individual>
     
    </Layout>
    );
  }
}

export default candidates2

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`