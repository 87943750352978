import React from "react";
import ReactModal from "react-modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'


class IndCand2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedCandidate: null
    };

    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  
  }


  handleOpenModal (id) {
    console.log("id===========" + id)
    this.setState({ 
                    showModal: true,
                    selectedCandidate: id
                  });
  }
  
  handleCloseModal () {
    this.setState({
                    showModal: false,
                    selectedCandidate: null
                  });
  }
  

    render() {
      return (<div className="cand2-container">
        <ul>
        {
          this.props.dataD.map((candidate)  => {
            return (
              <li className="candidate-box">
                  <h3>{candidate.first_name} {candidate.last_name}</h3>
                  <p>{candidate.party}</p>
                  <p>{candidate.constituency}</p>
                  <button className="candidates_btn" onClick={() => this.handleOpenModal(candidate)}>View</button>
              </li>
              )
        })
        }
        </ul>

        {this.state.selectedCandidate != null && (
        
                  <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="candidate-modal"
                    overlayClassName="Overlay"
                    onRequestClose={this.handleCloseModal}
                  >
                     <h1>{this.state.selectedCandidate.first_name} {this.state.selectedCandidate.last_name}</h1>
                     <h3>{this.state.selectedCandidate.party} candidate for {this.state.selectedCandidate.constituency}</h3>
                     <br/>
                     
                     { this.state.selectedCandidate.sections.map((section) => {
                                                    

                                                    return  <div>
                                                            <h3>{section.section_name_pretty}</h3>
                                                            <Container>
                                                            
                                                              {section.questions.map((question) => {
                                                                
                                                                            return <Row>
                                                                                        <Col sm={8} className="modal-col col-question">{question.question_pretty_name}</Col>
                                                                                        <Col sm={4} className="modal-col">{question.question_answer}</Col>
                                                                                    </Row>
                                                              })}

                                                              {section.questions.map((question) => {
                                                                
                                                                            return <Row>
                                                                                        <Col sm={8} className="modal-col col-question">{question.question_pretty_name}</Col>
                                                                                        <Col sm={4} className="modal-col">{question.question_answer}</Col>
                                                                                    </Row>
                                                                })}
                                                          </Container>
                                                          <br/><br/>
                                                          </div>
                                                    
                     })}

                    <button className="candidates_btn" onClick={this.handleCloseModal}>Close</button>
                  </ReactModal>
        
     
      )}
      </div>
      )
      }

}

export default IndCand2


