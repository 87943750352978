import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import JSONData from "../../../content/assets/candidates.json"
import IndCand2 from "./indcandidates";


const CanComp2Individual= () => {
    const [filteredResult, setFilteredResult] = useState([])

    useEffect(()=>{ // This will run after 1st render

      setFilteredResult(JSONData.candidates);
    
    },[]);

    const result = JSONData.candidates;
    const headings = JSONData.headings;

    var elements=[];
    var tbl = [];
    var keys =Object.keys(result);

    const setKeyword = (event) => {
      const searchWord = event.target.value
      var newFilter;

      if(searchWord != "")
      {
          newFilter = result.filter((value) => {
          var name = value.first_name.toLowerCase() + " " +  value.last_name.toLowerCase()
          return name.includes(searchWord.toLowerCase());
        });
      }
      else {
        newFilter = result;
      }
      setFilteredResult(newFilter);
    }

  return <div>
           <div class="candidate-search">
              Search Candidates:  <input type="text" placeholder="Search.." name="search" onChange={setKeyword}/>
           </div>
            <div>
              <IndCand2 dataD={filteredResult}></IndCand2>
            </div>
          </div>

  
}

export default CanComp2Individual
